/*
 * @Author: your name
 * @Date: 2021-01-25 19:09:14
 * @LastEditTime: 2021-04-30 16:21:15
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\order\saleOrderList\api.js
 */
// import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
import http from "@/utils/request";
export function getList(data) {
    return dmshttp({
        url: 'getSalesOrderList',
        data
    })
}

export function batch(data) {
    return dmshttp({
        url: 'batchInvalidSalesOrder',
        data
    })
}


// 驳回
export function orderReject(data) {
    return dmshttp({
        url: 'rejectPurchaseOrder',
        data
    })
}



//取消审核(
export function cancleReject(data) {
    return dmshttp({
        url: 'deleteReserverBySoId',
        data
    })
}
//取消Order(
export function cancleOrder(data) {
    return dmshttp({
        url: 'cancelRetailOrder',
        data
    })
}

// 导出

export function exportOrder(data) {
    return dmshttp({
        url: 'exportSalesOrderList' + '&type=1',
        data,
        responseType: 'blob'
    })
}


//打印
export function salesOrderPrint(data) {
    return dmshttp({
        url: 'salesOrderPrint',
        data
    })
}

export function changeTransFlag(data) {
    return http({
        url: '/marketActivity/changeTransFlag.nd',
        method: 'post',
        data
    })
}